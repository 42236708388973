<template>
  <b-card class="border border-dark shadow-none">
    <b-card-text>
      <b-container>
        <b-row>
          <b-col md="12">
            <h5 class="font-weight-bolder">
              Precios del producto
            </h5>
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Precio unitario factura USD"
              :tags="[{ value: product.UnitPriceInvoiceUsd, type: 'currency' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Tipo de cambio"
              :tags="[{ value: product.Tc, type: 'currency' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Precio unitario factura M/N"
              :tags="[{ value: product.UnitPriceInvoiceMn, type: 'currency' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Costeo MN'S C/Utilidad (5%) M/N"
              :tags="[{ value: product.Ccost, type: 'currency' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Precio proveedor USD"
              :tags="[{ value: product.ProvidersPriceUsd, type: 'currency' }]"
            />
          </b-col>
          <b-col md="3">
            <InformationBasicCard
              title="Costeo proveedor M/N"
              :tags="[{ value: product.ProvidersCostingMn, type: 'currency' }]"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BContainer,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BContainer,
    InformationBasicCard,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
}
</script>
