<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        xl="9"
        lg="10"
        md="12"
      >
        <b-overlay
          :show="isLoadingProducts"
          rounded="sm"
        >
          <template v-if="!isLoadingProducts">
            <div class="mb-2">
              <DetailsOptions
                :product="product"
                :warehouse="true"
                :return="'outcomes'"
              />
            </div>
            <ExtendedDetails :product="product" />
            <ProductDescriptionCard
              :product="product"
            />
            <PricesListCard :product="product" />
            <PricesProductCard
              v-if="$ability.can('read', 'Product_prices')"
              :product="product"
            />
            <PiecesListCard
              :pieces="product.pieces"
            />
            <DimentionsProductCard
              v-if="$ability.can('read', 'Product_dimensions')"
              :product="product"
            />
          </template>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BRow, BCol, BOverlay, BContainer,
} from 'bootstrap-vue'

import PricesListCard from '@/modules/production/products/components/PricesListCard.vue'
import PiecesListCard from '@/modules/production/products/components/PiecesListCard.vue'
import PricesProductCard from '@/modules/production/products/components/PricesProductCard.vue'
import DetailsOptions from '@/modules/production/products/components/product-card/DetailsOptions.vue'
import DimentionsProductCard from '@/modules/production/products/components/DimentionsProductCard.vue'
import ExtendedDetails from '@/modules/production/products/components/product-card/ExtendedDetails.vue'
import ProductDescriptionCard from '@/modules/production/products/components/ProductDescriptionCard.vue'

export default {
  name: 'OutcomesDetails',
  components: {
    BCol,
    BRow,
    BOverlay,
    BContainer,
    PricesListCard,
    PiecesListCard,
    DetailsOptions,
    ExtendedDetails,
    PricesProductCard,
    DimentionsProductCard,
    ProductDescriptionCard,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('products', ['product', 'isLoadingProducts']),
    ...mapGetters({
      getProductPiecesQuery: 'products/getProductPiecesQuery',
    }),
    productPiecesQuery: {
      get() { return this.getProductPiecesQuery },
      set(value) { this.setProductPiecesQuery(value) },
    },
  },
  async created() {
    try {
      await this.loadProduct({ id: this.id, withPieces: true })
    } catch (error) {
      if (error.response.status === 404) {
        this.$router.push({ name: 'error-404' })
      }
    }
  },
  methods: {
    ...mapActions({
      loadProduct: 'products/loadProduct',
      setProductPiecesQuery: 'products/setProductPiecesQuery',
    }),
  },
}
</script>
